<style lang="scss">
#f-succes {
  .list-unstyled {
    li {
      label {
        font-size: 14px;
      }
      .detail {
        font-weight: bold;
        margin-left: 5px;
      }
    }
  }
  .sub-header {
    font-size: 16px;
    font-weight: 500;
  }
  .img-outlet-logo {
    width: 90px;
    height: 60px;
  }
}
</style>
<template>
  <section
    class="content d-flex flex-column flex-column-fluid"
    id="f-succes"
    ref="htmlTempForPdf"
  >
    <div class="d-flex flex-column-fluid">
      <div class="container-fluid mb-25">
        <div class="row" v-if="loadingData">
          <div
            class="col-md-4 col-12 offset-md-4 offset-0 text-center"
            style="
              background: white;
              border: 1px solid rgb(204, 204, 204);
              padding: 10px;
            "
          >
            <loadingSpinner></loadingSpinner>
            <br />
            <h6>Please Wait, we are get your appointment details.</h6>
          </div>
        </div>
        <div class="row" v-if="hasData && !loadingData">
          <div
            class="col-md-4 col-12 offset-md-4 offset-0"
            style="
              background: white;
              border: 1px solid rgb(204, 204, 204);
              padding: 10px;
            "
          >
            <ul style="display: flex; list-style: none">
              <li style="width: 100%">
                <h5> Service: {{ appointmentData.service_name }}</h5>
                <p>
                  We have also e-mailed your appointment details to your email
                  address.
                </p>
              </li>
              <li style="width: 100%">
                <qr-code
                  :text="qrValue"
                  :size="90"
                  bg-color="#eef0f8"
                  error-level="L"
                  style="float: right"
                ></qr-code>
              </li>
            </ul>
            <!-- <div class="row">
               <div class=" col-md-6 col-12">
                  <canvas id="barcode" style="width: inherit; height: 95px"></canvas>
               </div>
             </div> -->
            <hr />
            <div class="row">
              <div class="col-md-12">
                <div class="row">
                  <div
                    class="col-md-9 col-sm-8 col-8"
                    v-if="appointmentData.appointment_date != null"
                  >
                    <p class="text-success sub-header">Appointment Details</p>

                    <h4>
                      {{ appointmentData.appointment_date | sDateFormate }}
                    </h4>
                    <h6 class="text-info">
                      {{
                        appointmentData.start_time
                          | timeFormat(appointmentData.appointment_date)
                      }}
                      {{ appointmentData.client_timezone }}
                      <!-- -
                      {{
                        appointmentData.end_time
                          | timeFormat(appointmentData.appointment_date)
                      }} -->
                    </h6>
                  </div>
                  <div
                    v-if="appointmentData.appointment_date != null"
                    class="col-md-3 col-sm-4 col-4"
                    style="border-left: 0.5px solid #ccc"
                  >
                    <div
                      class="text-center"
                      style="margin-top: 15px"
                      v-if="getAbbreviationCode.data.show_payment_option"
                    >
                      <p>Total Amount</p>
                      <h4 class="text-primary">
                        ${{ appointmentData.total_amount | toFixedTwo }}
                      </h4>
                    </div>
                  </div>
                </div>
                <hr v-if="appointmentData.appointment_date != null" />
                <!-- <div class="row">
                  <div class="col-12">
                    <canvas
                      id="barcode"
                      style="width: inherit; height: 75px;padding:0 10px 0 0"
                    ></canvas>
                  </div>
                </div> -->
                <div class="row">
                  <div class="col-md-6 col-12">
                    <p class="text-success sub-header">Personal Details</p>
                    <ul class="list-unstyled">
                      <li>
                        <label for="">First Name:</label>
                        <label for="" class="detail">{{
                          appointmentData.fname
                        }}</label>
                      </li>
                      <li v-if="appointmentData.mname != ''">
                        <label for="">Middle Name: </label>
                        <label for="" class="detail">{{
                          appointmentData.mname
                        }}</label>
                      </li>
                      <li>
                        <label for="">Last Name: </label>
                        <label for="" class="detail">{{
                          appointmentData.lname
                        }}</label>
                      </li>

                      <li>
                        <label for="">Email:</label>
                        <label for="" class="detail">{{
                          appointmentData.email
                        }}</label>
                      </li>
                      <li>
                        <label for="">Contact No.: </label>
                        <label for="" class="detail">{{
                          appointmentData.phone_no
                        }}</label>
                      </li>
                    </ul>
                  </div>
                  <div class="col-md-6 col-12">
                    <p class="text-success sub-header">Location Details</p>
                    <div class="row">
                      <!-- <div class="col-3">
                        <img
                          src="https://www.pngitem.com/pimgs/m/549-5495566_image7-dump-truck-logo-design-hd-png-download.png"
                          alt=""
                          class="img-outlet-logo"
                        />
                      </div> -->
                      <div class="col-12">
                        <ul class="list-unstyled">
                          <li>
                            <label for="">Name:</label
                            ><label for="" class="detail" v-if="appointmentData.location_job">{{
                              appointmentData.job_client_name
                            }}</label>
                            <label for="" class="detail" v-else>{{
                              appointmentData.name
                            }}</label>
                          </li>
                          <li>
                            <label for="">Address: </label
                            ><label for="" class="detail" v-if="appointmentData.location_job">{{
                              appointmentData.job_client_address
                            }}</label>
                            <label for="" class="detail" v-else>{{
                              appointmentData.address
                            }}</label> 
                          </li>
                          <li>
                            <label for=""
                              ><a
                                :href="
                                  'http://www.google.com/maps/place/' +
                                    appointmentData.lat +
                                    ',' +
                                    appointmentData.long
                                "
                                target="_blank"
                                >Find in the map</a
                              ></label
                            >
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div
                    v-if="appointmentData.appointment_date == null"
                    class="col-md-4 col-sm-5 col-5"
                    style="border-left: 0.5px solid #ccc"
                  >
                    <div
                      class="text-center"
                      v-if="getAbbreviationCode.data.show_payment_option"
                    >
                      <p>Total Amount</p>
                      <h4 class="text-primary">
                        ${{ appointmentData.total_amount | toFixedTwo }}
                      </h4>
                    </div>
                  </div>
                  <div
                    :class="[
                      appointmentData.appointment_date == null
                        ? 'col-8'
                        : 'col-12'
                    ]"
                  >
                    <button
                      @click="goHome()"
                      class="btn btn-danger"
                      style="float: right"
                      :class="[
                        appointmentData.appointment_date == null ? 'mt-4' : ''
                      ]"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-if="!hasData && !loadingData">
          <div
            class="col-md-4 col-12 offset-md-4 offset-0"
            style="
              background: white;
              border: 1px solid rgb(204, 204, 204);
              padding: 10px;
            "
          >
            <h5>Sorry, we didn't found your appointment details</h5>
            <button
              class="btn btn-danger"
              style="float: right"
              @click="goHome()"
            >
              Go Back
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import VueQRCodeComponent from "vue-qrcode-component";
import loadingSpinner from "@/components/frontend/spinner.vue";
import moment from "moment";
import { SET_CHECK_F_ABBREVIATION_CODE } from "@/core/services/store/mutations.type";

import { FETCH_SELF_APPOINMENT } from "@/core/services/store/actions.type";
import * as PDF417 from "pdf417-generator";
import { mapGetters } from "vuex";

export default {
  components: {
    "qr-code": VueQRCodeComponent,
    loadingSpinner
  },
  data() {
    return {
      token: "",
      ap_id: "",
      qrValue: "",
      appointmentData: {},
      loadingData: false,
      hasData: false,
      slug: "",
      route_name: ""
    };
  },
  computed: {
    ...mapGetters(["getAbbreviationCode"])
  },
  methods: {
    goHome() {
      this.$store.commit(SET_CHECK_F_ABBREVIATION_CODE, {
        data: { show_payment_option: true },
        success: "none"
      });
      if (this.slug != "" && this.route_name != "") {
        this.$router.push({
          name: this.route_name,
          params: { slug: this.slug }
        });
      } else {
        this.$router.push({
          name: "frontend.home",
          params: { slug: this.$route.params.client_slug }
        });
      }
    },
    generateBarCode(value) {
      var canvas = document.getElementById("barcode");
      PDF417.draw(value, canvas);
    }
  },

  created() {
    this.loadingData = true;
    this.token = this.$route.query.token;
    this.ap_id = this.$route.query.app_id;
    this.slug = this.$route.query.slug ? this.$route.query.slug : "";
    this.route_name = this.$route.query.route_name
      ? this.$route.query.route_name
      : "";
    this.qrValue =
      window.location.origin +
      "/" +
      this.$route.params.client_slug +
      "/appointment/view?token=" +
      this.token +
      "&&app_id=" +
      this.ap_id;
    var data = {
      token: this.token,
      appointment_id: this.ap_id
    };
    this.$store
      .dispatch(FETCH_SELF_APPOINMENT, data)
      .then(res => {
        this.appointmentData = res.data;
        this.loadingData = false;
        this.hasData = res.hasData;
        var DBB = this.$moment(res.data.dob).format("MMDDYYYY");
        var DBC = res.data.gender == "Female" ? 2 : 1;
        var DAU = ((res.data.height * 12) / 100).toFixed(2);
        var value =
          "@\n\nANSI 636020090102DL00410279ZC03200010DLDAQ171774992\nDCA\nDCB\nDCD\nDBA\nDCS" +
          res.data.lname +
          "\nDAC" +
          res.data.fname +
          "\nDAD" +
          res.data.mname +
          "\nDBD\nDBB" +
          DBB +
          "\nDBC" +
          DBC +
          "\nDAY" +
          res.data.eye_color +
          "\nDAU0" +
          DAU * 100 +
          " in\nDAG" +
          "\nDAI" +
          res.data.city +
          "\nDAJ" +
          res.data.address +
          "\nDAK" +
          res.data.zipcode +
          "\nDAQ\nDCF\nDCG\nDDE\nDDF\nDDG";

        setTimeout(() => {
          this.generateBarCode(value);
        }, 100);
      })
      .catch(() => {
        this.loadingData = false;
      });
  },
  filters: {
    toFixedTwo(value) {
      return parseFloat(value).toFixed(2);
    },

    timeFormat: function(value, date) {
      if (date == null) {
        return;
      }
      var fullDate = date + " " + value;
      var a = moment(fullDate).format("hh:mm a");
      return a;
    },
    sDateFormate: function(date) {
      if (date == null) {
        return;
      }
      return moment(String(date)).format("dddd, MMMM DD, YYYY");
    }
  }
};
</script>
